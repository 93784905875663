import React, { StrictMode } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import SofarApolloProvider from 'src/apollo/SofarApolloProvider';

import CookieInitializer from 'app/lib/CookieInitializer';
import { initializeGA4 } from 'app/lib/google';
import ThirdPartyScriptLoader from 'app/lib/ThirdPartyScriptLoader';
import AnalyticsProvider from 'app/shared/context/Analytics';
import AuthProvider from 'app/shared/context/Auth';
import CityProvider from 'app/shared/context/City';
import FeatureFlagsProvider from 'app/shared/context/FeatureFlags';
import PermissionsProvider from 'app/shared/context/Permissions';

import App from './App';

initializeGA4();

const ROOT = document.getElementById('__app') as HTMLElement;

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <CookieInitializer>
        <ThirdPartyScriptLoader>
          <SofarApolloProvider>
            <HelmetProvider>
              <GoogleOAuthProvider
                clientId={process.env.GOOGLE_CLIENT_ID || ''}
              >
                <AuthProvider>
                  <PermissionsProvider>
                    <CityProvider>
                      <AnalyticsProvider>
                        <FeatureFlagsProvider>
                          <App />
                        </FeatureFlagsProvider>
                      </AnalyticsProvider>
                    </CityProvider>
                  </PermissionsProvider>
                </AuthProvider>
              </GoogleOAuthProvider>
            </HelmetProvider>
          </SofarApolloProvider>
        </ThirdPartyScriptLoader>
      </CookieInitializer>
    </BrowserRouter>
  </StrictMode>,
  ROOT
);
