import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { initializeGTM } from 'app/lib/google';

import { setAttributionCookies } from './setAttributionCookies';
import { setSnapchatCookies } from './setSnapchatCookies';
import { setTikTokCookies } from './setTikTokCookies';

initializeGTM();

const CookieInitializer: React.FC = ({ children }) => {
  const [cookiesInitialized, setCookiesInitialized] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!cookiesInitialized) {
      setTikTokCookies(searchParams);
      setSnapchatCookies(searchParams);
      setAttributionCookies(searchParams);
      setCookiesInitialized(true);
    }
  }, [cookiesInitialized, searchParams]);

  if (!cookiesInitialized) {
    return null;
  }

  return <>{children}</>;
};

export default CookieInitializer;
